import React, { createContext, useContext, useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import AWS from 'aws-sdk'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
const AuthContext = createContext()
export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        Auth.currentSession().then((user) => {
          const { payload } = user.getIdToken()
          if (payload && payload['cognito:groups']) {
            console.log(payload['cognito:groups'])
          }
        })
        setCurrentUser(user)
      } catch (error) {
        setCurrentUser(null)
      }
    }
    fetchUser()
  }, [])

  const getUsers = async () => {
    const region = process.env.REACT_APP_AWS_REGION
    const userPoolId = process.env.REACT_APP_AWS_USER_POOL_ID
    const IdentityPoolId = process.env.REACT_APP_AWS_IDENTITY_POOL_ID
    const clientId = process.env.REACT_APP_AWS_APP_CLIENT_ID
    AWS.config.update({
      region: region,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    })

    const cognitoUser = await authenticateUser(userPoolId, clientId)
    console.log('cognitoUser : ', cognitoUser)
    const params = {
      UserPoolId: userPoolId,
      // AttributesToGet: [
      //   'given_name',
      //   'family_name',
      //   'email',
      //   'gender',
      //   'birthdate',
      //   'sub',
      //   'custom:medicalDepartment',
      //   'custom:organization',
      //   'custom:position',
      //   'custom:registeredNumber',
      //   'custom:occupation',
      // ],
      // AccessToken: cognitoUser.signInUserSession.accessToken.jwtToken, // アクセストークンを指定します
    }
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
      region: region,
    })

    const userList = await cognitoidentityserviceprovider.listUsers(params).promise()
    console.log('userList : ', userList)
    return userList.Users
  }

  const authenticateUser = (userPoolId, clientId) => {
    return new Promise((resolve, reject) => {
      const poolData = {
        UserPoolId: userPoolId,
        ClientId: clientId,
      }
      const userPool = new CognitoUserPool(poolData)
      const cognitoUser = userPool.getCurrentUser()

      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err)
          } else {
            resolve(cognitoUser)
          }
        })
      } else {
        reject(new Error('User not found'))
      }
    })
  }
  const confirmSignUp = async (username, code) => {
    try {
      await Auth.confirmSignUp(username, code)
    } catch (error) {
      console.log('error confirming sign up', error)
    }
  }

  const resendSignUp = async (username) => {
    try {
      await Auth.resendSignUp(username)
      console.log('code resent successfully')
    } catch (err) {
      console.log('error resending code: ', err)
    }
  }

  const signUp = async (username, password, email, attributes) => {
    try {
      Auth.configure()
      const user = await Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      })
      setCurrentUser(user)
    } catch (error) {
      console.log('error signing up:', error)
    }
  }
  const signIn = async (email, password) => {
    try {
      const user = await Auth.signIn(email, password, {
        mfaType: 'SMS',
      })
      return user
    } catch (error) {
      return error
    }
  }

  const confirmSignIn = async (email, code) => {
    try {
      const user = await Auth.confirmSignIn(email, code, 'SMS_MFA')
      console.log('confirmSignIn:user', user)
      return user
    } catch (error) {
      console.error('Code verification failed:', error)
      return error
    }
  }

  const logout = async () => {
    try {
      await Auth.signOut()
      setCurrentUser(null)
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  const value = {
    confirmSignIn,
    confirmSignUp,
    currentUser,
    logout,
    resendSignUp,
    setCurrentUser,
    signIn,
    signUp,
    getUsers,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
AuthProvider.propTypes = {
  children: PropTypes.node,
}
